import { useEffect, useState } from "react";
// for work_images
import work1 from "../assets/images/work_images/green.png";
import work2 from "../assets/images/work_images/enough.png";
import work3 from "../assets/images/work_images/hintergrund2.png";
import work4 from "../assets/images/work_images/personal-4.png";
import work5 from "../assets/images/work_images/school.png";
import work6 from "../assets/images/work_images/t.png";
import work7 from "../assets/images/work_images/winter_wonderland.png";
// works small images
import workSmall1 from "../assets/images/work_images/small/green.png";
import workSmall2 from "../assets/images/work_images/small/enough.png";
import workSmall3 from "../assets/images/work_images/small/hintergrund2.png";
import workSmall4 from "../assets/images/work_images/small/personal-4.png";
import workSmall5 from "../assets/images/work_images/small/school.png";
import workSmall6 from "../assets/images/work_images/small/t.png";
import workSmall7 from "../assets/images/work_images/small/winter_wonderland.png";
// blog post images
import blog1 from "../assets/images/blog_images/goloko.png";
import blog2 from "../assets/images/blog_images/jwlogo.png";
import blog3 from "../assets/images/blog_images/WelschBar.png";
// blog image small
import blogSmall1 from "../assets/images/blog_images/small/goloko.png";
import blogSmall2 from "../assets/images/blog_images/small/jwlogo.png";
import blogSmall3 from "../assets/images/blog_images/small/WelschBar.png";

import img1 from "../assets/images/slider/brand-1.png";
import img2 from "../assets/images/slider/brand-2.png";
import img3 from "../assets/images/slider/brand-3.png";
import img4 from "../assets/images/slider/brand-4.png";
import img5 from "../assets/images/slider/brand-5.png";

import d1 from "../assets/images/logo/aaaaaaa.png";
import d2 from "../assets/images/logo/asdf.png";
import d3 from "../assets/images/logo/StyleTile.png";
//  icon use as img here
import illustratorIcon from "../assets/images/icons/illustrator.png";
import indesignIcon from "../assets/images/icons/indesign.png";
import canvaIcon from "../assets/images/icons/canva.png";
import officeIcon from "../assets/images/icons/office.png";
import webDevelopmentIcon from "../assets/images/icons/web-development.png";
// contact image
import iconPhone from "../assets/images/contact/phone-call 1.png";
import iconEmail from "../assets/images/contact/email 1.png";
import iconMap from "../assets/images/contact/map 1.png";
import { CgNotes } from "react-icons/cg";
import { FaRegUser } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { FiCodesandbox } from "react-icons/fi";
import { RiContactsBookLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

import { DesignManual } from "../../src/assets/images/pdfs/Designmanual.pdf"

// react icons
import { MdOutlineBusinessCenter, MdOutlineSchool } from "react-icons/md";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme");

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem("theme", "light");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // fillter portfilo data
  const handleData = (text) => {
    if (text === "All") {
      setData(workItems);
    } else {
      const findData = workItems.filter((item) => item.tag === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = (id) => {
    const find = workItems.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items for Blogs  modal open
  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Elements for protfilo section
  const workItems = [
    {
      id: "1",
      tag: "Sonstiges",
      title: "DesignIT Website",
      img: d3,
      imgSmall: d3,
      bg: "#F2F2F0",
      langages: "Adobe InDesign",
      link: "../../../src/assets/images/pdfs/FertigeWebsite.pdf",
      linkText: "FertigeWebsite.pdf",
      description:
        "Eine selbst erstellte Agentur und deren dazugehörige Website waren im Berufskolleg einer unseren Aufgaben. In der pdf-Datei, welche in der Vorschau zu sehen ist, sehen Sie meine statistische Website zu „meinem Betrieb“. Mein Unternehmen soll eine Webdesign Firma sein, welche den Kunden Workshops anbieten, damit diesen beigebracht wird, wie man eine Internetseite selbst gestaltet.",
    },
    {
      id: "20",
      tag: "Logo",
      title: "Mein Logo",
      project: "Mein eigenes Logo",
      img: blog2,
      imgSmall: blogSmall2,
      bg: "#F2F2F0",
      langages: "Canva",
      description:
        "Mein eigenes Logo habe ich mit Hilfe von Canva erstellt. Es soll drei mal den Buchstaben „J“ abbilden für meinen Namen (Julia) und in dem Lehrraum den Buchstaben „W“ für meinen Nachnamen (Welsch). Schlicht und minimalistisch.",
    },
    {
      id: "2",
      tag: "Logo",
      title: "Golok",
      img: blog1,
      imgSmall: blogSmall1,
      bg: "#F2F2F0",
      project: "Golok - Logo",
      langages: "Adobe Illustrator",
      description:
        "Die Aufgabe meines Lehrers war ein Getränk umzubenennen und das Logo mit dem Abbild eines Tieres neu zu gestalten. Hier habe ich mich für das Getränk „moloko“ entschieden. Das „G“ steht hier für die Giraffe und die Minzblätter sollen ein weiteres „O“ darstellen.",
    },
    {
      id: "21",
      tag: "Logo",
      title: "Welsch Bar",
      project: "Welschbar - Logo",
      img: blog3,
      imgSmall: blogSmall3,
      bg: "#F2F2F0",
      langages: "Adobe Illustrator",
      description:
        "In diesem Projekt mussten wir das Logo unserer eigenen Bar erstellen. Das Aushängeschild. Ich habe hier an eine Strandbar gedacht und diese nach mir benannt. Der Ton Orange, wie auch die Palmen sollen an den Strand erinnern.",
    },
    {
      id: "5",
      tag: "Sonstiges",
      title: "DesignIT Flyer",
      img: d2,
      imgSmall: d2,
      bg: "#F2F2F0",
      langages: "Adobe InDesign",
      link: "../../../src/assets/images/pdfs/Flyer.pdf",
      linkText: "Flyer.pdf",
      project: "Flyer",
      description:
      "Wie schon erwähnt, mussten wir ein Unternehmen erfinden und verschiedene Aufgaben bewältigen. Hier habe ich einen Flyer hierzu erstellt. Ich habe mich nach den Farben des Logos gerichtet. Die andere Seite des Flyers ist als pdf-Datei in der Vorschau zu sehen.",
    },
    {
      id: "7",
      tag: "Sonstiges",
      title: "DesignIT Design Manual",
      project: "Corporate Design Manual",
      img: d1,
      imgSmall: d1,
      bg: "#F2F2F0",
      langages: "Adobe Illustrator ",
      link: "../../../src/assets/images/pdfs/Designmanual.pdf",
      linkText: "Designmanual.pdf",
      description:
      "Das Logo kennzeichnet ein Unternehmen und ist somit ziemlich wichtig. Hierzu habe ich ein komplettes Design Manual erstellt. welches das Logo und die Firma in Farbe, Größe und das insgesamt Bild bis ins Detail beschreibt. Die weiteren Seiten dazu finden sie in der Vorschau verlinkt.",
    },
    {
      id: "4",
      tag: "Hintergrund",
      title: "black & white summernight",
      img: work4,
      imgSmall: workSmall4,
      bg: "#F2F2F0",
      langages: "Canva",
      project: "Desktop - Hintergrundbild",
      description:
        "Ich habe hier ein Desktop Hintergrundbild mit Hilfe von Canva erstellt. Dieser war für den Monat „Juni“ gedacht. Da für den Sommer bekanntlich bunte Farben verwendet werden, wollte ich etwas Abwechslung rein bringen und dieses schwarz und weiß gestalten. Jedoch wurden Bilder verwendet die tatsächlich an den Sommer erinnern. ",
    },
    {
      id: "6",
      tag: "Hintergrund",
      title: "summer action ",
      img: work3,
      imgSmall: workSmall3,
      bg: "#F2F2F0",
      langages: "Canva",
      project: "Desktop - Hintergrundbild",
      description:
        "Ein Hintergrundbild welches vom Design her, einer meiner Favoriten ist. Das Gesamtbild schafft eine angenehme Atmosphäre. Die Bücher und die abgebildeten Blumen scheinen einen mit einer neutralen Hintergrundfarbe zu beruhigen.",
    },
    {
      id: "10",
      tag: "Hintergrund",
      title: "spring",
      img: work2,
      imgSmall: workSmall2,
      bg: "#F2F2F0",
      langages: "Canva",
      project: "Desktop - Hintergrundbild",
      description:
        "Hier habe ich eine andere Art design ausprobiert. Es soll eine Internet Plattform darstellen. Eine Seite ähnlich wie Pinterest. Diesmal etwas bräunlich gestaltet, wie manche Tage zum Frühling hin auch scheinen. ",
    },
    {
      id: "11",
      tag: "Hintergrund",
      title: "mint april",
      img: work1,
      imgSmall: workSmall1,
      bg: "#F2F2F0",
      langages: "Canva",
      project: "Desktop - Hintergrundbild",
      description:
        "Einer meiner ersten Hintergrundbilder. Die mintgrüne Farbe soll einen lebhaften und frischen Look vermitteln, der an das erwachende Grün im Frühling erinnert. ",
    },
    {
      id: "12",
      tag: "Hintergrund",
      title: "winter wonderland",
      img: work7,
      imgSmall: workSmall7,
      bg: "#F2F2F0",
      langages: "Canva",
      project: "Desktop - Hintergrundbild",
      description:
        "Viele würden ein Wallpaper für den Dezember eher in Weihnachtlichen Tönen gestalten, wie rot oder grün. Ich habe jedoch eher an die Farbe weiß gedacht. Die Ausstrahlung einer kühlen und sauberen Umgebung war für mich besonders wichtig, da es mir persönlich einen klaren Kopf verschafft.",
    },
    {
      id: "13",
      tag: "Hintergrund",
      title: "perfectly imperfect",
      img: work5,
      imgSmall: workSmall5,
      bg: "#F2F2F0",
      langages: "Canva",
      project: "Desktop - Hintergrundbild",
      description:
        "Das Gesamtbild spiegelt von den Farben und den Bildern den Herbst. In der Zeit möchte man lieber zuhause sitzen und ein Buch lesen, zuhause sitzen und eine Serie schauen. Wenn ich an den Herbst denke sehe ich die Farbe braun, ein kühleres braun.",
    },
    {
      id: "14",
      tag: "Hintergrund",
      title: "blue may",
      img: work6,
      imgSmall: workSmall6,
      project: "Desktop - Hintergrundbild",
      bg: "#F2F2F0",
      langages: "Canva",
      description:
        "„Mai“ es ist Frühling und nicht mehr weit zum Sommer hin. die Blumen werden größer und der Himmel schöner. Nicht mehr gräulich, sondern bläulich. Somit habe ich mich für die Farbe blau entschieden und alles in der Farbe gestaltet. Ich habe Bilder ausgewählt die mich an den Frühling erinnern und ein angenehmes Ambiente ausstrahlen.",
    },
  ];
  const [data, setData] = useState(workItems);

  // Elements for Blogs section
  const blogsData = [
    {
      id: "1",
      img: blog1,
      imgSmall: blogSmall1,
      date: "177 April",
      category: "Inspiration",
      title: "How to Own Your Audience by Creating an Email List.",
      bg: "#FCF4FF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
  ];

  // Menu items for Homepage One
  const menuItem = [
    {
      id: "01",
      name: "Home",
      link: "/home/homePage",
      icon: <AiOutlineHome />,
    },
    {
      id: "02",
      name: "About",
      link: "/home/about",
      icon: <FaRegUser />,
    },
    {
      id: "06",
      name: "Resume",
      link: "/home/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Works",
      link: "/home/works",
      icon: <FiCodesandbox />,
    },
    {
      id: "05",
      name: "Contact",
      link: "/home/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Menu items for Homepage Two
  const menuItemTwo = [
    {
      id: "01",
      name: "Über mich",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: "02",
      name: "Résumé",
      link: "/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Portfolio",
      link: "/works",
      icon: <FiCodesandbox />,
    },
    {
      id: "05",
      name: "Kontakt",
      link: "/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Slider image for Clients
  const sliderImg = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img1,
    img2,
    img3,
    img4,
    img5,
  ];

  // experience items for about page
  const experienceArray = [
    {
      id: "1",
      icon: illustratorIcon,
      title: "Adole Illustrator",
      des: "2 Jahre Erfahrung, hauptsächlich Logos entworfen",
      color: "#F2F2F0",
      bg: "#F2F2F0",
    },
    {
      id: "2",
      icon: indesignIcon,
      title: "Adobe InDesign",
      des: "1 Jahr Erfahrung, Flyer sowie statische Websites erstellt",
      color: "#DDA10C",
      bg: "#F2F2F0",
    },
    {
      id: "3",
      icon: canvaIcon,
      title: "Canva",
      des: "3 Jahre Erfahrung, Gestaltung von Wallpapern, Lebensläufen und Bewerbungen",
      color: "#8774FF",
      bg: "#F2F2F0",
    },
    {
      id: "4",
      icon: officeIcon,
      title: "Microsoft Office",
      des: "Grundlegende Kenntnisse in Word, Powerpoint und Excel",
      color: "#FF6080",
      bg: "#F2F2F0",
    },

    // {
    //   id: "6",
    //   icon: webDevelopmentIcon,
    //   title: "Web Entwicklung",
    //   des: "Erstellung von einfachen Websites mit HTML und CSS",
    //   color: "#269FFF",
    //   bg: "#F2F2F0",
    // },
  ];

  // Resume items for Resume page
  const resumeArray = [
    {
      type: "Schulische Laufbahn",
      icon: MdOutlineSchool,
      id: "01",
      date: "2019 - 2021",
      title: "Fachhochschulreife",
      place: "Josef-Durler-Schule, Rastatt",
      bg: "#F2F2F0",

      id1: "02",
      date1: "2013 - 2019",
      title1: "Realschulabschluss",
      place1: "August-Renner-Realschule, Rastatt",
      bg1: "#F2F2F0",
    },
    {
      type: "Berufserfahrungen",
      icon: MdOutlineBusinessCenter,
      id: "03",
      date: "10/2022 - 03/2023",
      title: "Praktikum, Elekrodienst",
      place: "Elektrodienst Neudörfer GmbH, Rastatt",
      bg: "#F2F2F0",

      id1: "04",
      date1: "07/2022 - 09/2022",
      title1: "Teilzeit als Bäckereifachverkäuferin",
      place1: "EDEKA Liebich, Rastatt",
      bg1: "#F2F2F0",

      id2: "05",
      date2: "08/2021 - 09/2021",
      title2: "Teilzeit als Kino Servicekraft",
      place2: "Multiplex Rastatt GmbH & Co.KG, Rastatt",
      bg2: "#F2F2F0",

      id3: "06",
      date3: "10/2019 - 07/2022",
      title3: "Aushilfe im Einzelhandel",
      place3: "EDEKA Liebich, Rastatt",
      bg3: "#F2F2F0",
    }
  ];

  const resumeeArray1 = [
    {
      type: "Schulische Laufbahn",
      icon: MdOutlineSchool,
      id: "01",
      date: "2019 - 2021",
      title: "Fachhochschulreife",
      place: "Josef-Durler-Schule, Rastatt",
      bg: "#F2F2F0",

      id1: "02",
      date1: "2013 - 2019",
      title1: "Realschulabschluss",
      place1: "August-Renner-Realschule, Rastatt",
      bg1: "#F2F2F0",
    }
  ];
  
  const resumeeArray2 = [
    {
      type: "Berufserfahrungen",
      icon: MdOutlineBusinessCenter,
      id: "03",
      date: "10/2022 - 03/2023",
      title: "Praktikum, Elekrodienst",
      place: "Elektrodienst Neudörfer GmbH, Rastatt",
      bg: "#F2F2F0",

      id1: "04",
      date1: "07/2022 - 09/2022",
      title1: "Teilzeit als Bäckereifachverkäuferin",
      place1: "EDEKA Liebich, Rastatt",
      bg1: "#F2F2F0",

      id2: "05",
      date2: "08/2021 - 09/2021",
      title2: "Teilzeit als Kino Servicekraft",
      place2: "Multiplex Rastatt GmbH & Co.KG, Rastatt",
      bg2: "#F2F2F0",

      id3: "06",
      date3: "10/2019 - 07/2022",
      title3: "Aushilfe im Einzelhandel",
      place3: "EDEKA Liebich, Rastatt",
      bg3: "#F2F2F0",
    }
  ]

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: "01",
      color: "#8C0808",
      name: "Canva",
      number: "90",
    },
    {
      id: "02",
      color: "#BF0F0F",
      name: "Adobe Illustrator",
      number: "85",
    },
    {
      id: "03",
      color: "#F23D3D",
      name: "Adobe InDesign",
      number: "75",
    },
    {
      id: "03",
      color: "#F25C69",
      name: "Social Media",
      number: "95",
    },
  ];

  // Personal information for contact pages
  const contactArray = [
    {
      id: "01",
      icon: iconPhone,
      title: "Phone ",
      item1: "+452 666 386",
      item2: "+452 666 386",
      bg: "#FCF4FF",
    },
    {
      id: "02",
      icon: iconEmail,
      title: "Email ",
      item1: "support@gmail.com",
      item2: "example@gmail.com",
      bg: "#EEFBFF",
    },
    {
      id: "03",
      icon: iconMap,
      title: "Address ",
      item1: "Maount View, Oval",
      item2: "Road, New York, USA",
      bg: "#F2F4FF",
    },
  ];

  return {
    handleTheme,
    check,
    local,
    handleData,
    data,
    singleData,
    handleModelData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
    menuItem,
    NavLink,
    menuItemTwo,
    experienceArray,
    sliderImg,
    resumeArray,
    lineArray,
    contactArray,
    resumeeArray1,
    resumeeArray2,

  };
};

export default AllData;
