import React from "react";
// import cvFile from "../assets/images/cv.webp";
import {
  FaCalendarAlt,
  FaEnvelopeOpenText,
  FaMapMarkerAlt,
  FaMobileAlt,
} from "react-icons/fa";
import img from "../assets/images/about/julxaw.jpg";
import downloadIcon from "../assets/images/download.png";
import cvPDF from "../assets/images/contact/Julia_Welsch.pdf"

const HomeCard = () => {
  return (
    <div>
      <div className="w-full mb-6 lg:mb-0  mx-auto   relative bg-white text-center dark:bg-[#111111] px-6 rounded-[20px] mt-[180px] md:mt-[220px] lg:mt-0 shadow-2xl">
        <img
          src={img}
          className="w-[240px] absolute left-[50%] transform -translate-x-[50%] h-[240px] mx-auto  rounded-[20px] -mt-[140px] shadow-2xl border-2 border-[#732922]"
          alt=""
        />
        {/* Social card */}
        <div className="pt-[100px] pb-8">
          <h1 className="mt-6 mb-1 text-5xl font-semibold  dark:text-white">
            Julia Welsch
          </h1>
          <h3 className="mb-4 text-[#7B7B7B] inline-block dark:bg-[#1D1D1D] px-5 py-1.5 rounded-lg dark:text-[#A6A6A6]  ">
            Mediendesign
          </h3>

          {/* Social Links */}

          <div className="flex justify-center space-x-3">
          <h2 className="after-effect right-24 bg-[#595047]"></h2>
          </div>

          {/* personal information */}
          <div className="p-7 rounded-2xl mt-7  bg-[#F2F2F0] dark:bg-[#1D1D1D]">
            <div className="flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] pb-2.5">
              <span className="flex-shrink-0 socialbtn bg-white dark:bg-black text-[#BF3636] shadow-md">
                <FaMobileAlt />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Telefonnummer
                </p>
                <p className="dark:text-white break-all">
                  {" "}
                  <a
                    className="hover:text-[#736151] duration-300 transition"
                    href="tel:+4917620336479"
                  >
                    +49 176 20336479
                  </a>
                </p>
              </div>
            </div>

            <div className="flex  border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5">
              <span className="flex-shrink-0 socialbtn bg-white dark:bg-black text-[#BF3636] shadow-md">
                <FaEnvelopeOpenText />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Email
                </p>
                <p className="dark:text-white break-all">
                  {" "}
                  <a
                    className="hover:text-[#FA5252] duration-300 transition"
                    href="/homeTwo/contact"
                  >
                    welschjulia5@gmail.com
                  </a>
                </p>
              </div>
            </div>

            <div className="flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5">
              <span className="flex-shrink-0 socialbtn bg-white dark:bg-black text-[#BF3636] shadow-md">
                <FaMapMarkerAlt />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Standort
                </p>
                <p className="dark:text-white break-all">Rastatt, Germany</p>
              </div>
            </div>

            <div className="flex  py-2.5">
              <span className="flex-shrink-0 socialbtn bg-white dark:bg-black text-[#BF3636] shadow-md">
                <FaCalendarAlt />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Geburtstag
                </p>
                <p className="dark:text-white break-all">13 Februar, 2003</p>
              </div>
            </div>
          </div>

          <a
            href={cvPDF}
            download
            className="inline-flex items-center mx-auto bg-gradient-to-r from-[#732922] to-[#BF3636] duration-200 transition ease-linear hover:bg-gradient-to-l px-8 py-3 text-lg text-white rounded-[35px] mt-6 hover:cursor-pointer hover:scale-105"
          >
            <img src={downloadIcon} alt="icon" className="mr-2" />
            CV herunterladen
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeCard;
